<script setup lang="ts">
import type { CmsBlock } from "@shopware-pwa/types";
import {
  getCmsLayoutConfiguration,
  getBackgroundImageUrl,
} from "@shopware-pwa/helpers-next";
import { resolveCmsComponent } from "@shopware-pwa/composables-next";
import { h } from "vue";

const props = defineProps<{
  content: CmsBlock;
}>();

const DynamicRender = () => {
  const { resolvedComponent, componentName, isResolved } = resolveCmsComponent(
    props.content,
  );

  if (resolvedComponent) {
    if (!isResolved)
      return h("div", {}, "Problem resolving component: " + componentName);

    const { cssClasses, layoutStyles } = getCmsLayoutConfiguration(
      props.content,
    );

    const rounded = props.content.customFields?.rounded === true;
    if (rounded) {
      cssClasses['rounded-xxl'] = true
      cssClasses['overflow-hidden'] = true
    }

    const backgroundColorClass = props.content.customFields?.backgroundColorClass
    if (backgroundColorClass) {
      cssClasses[backgroundColorClass] = true
    }

    if (layoutStyles.backgroundImage) {
      layoutStyles.backgroundImage = getBackgroundImageUrl(
        layoutStyles.backgroundImage,
        props.content,
      );
      layoutStyles.backgroundPositionX = props?.content?.customFields?.backgroundPositionX ?? 'center'
      layoutStyles.backgroundPositionY = props?.content?.customFields?.backgroundPositionY ?? 'center'
    }

    const containerStyles = {
      backgroundColor: layoutStyles.backgroundColor,
    };

    const blockName = props.content.name

    return h(
      "div",
      {
        id: blockName,
        style: containerStyles,
      },
      h(resolvedComponent, {
        content: props.content,
        style: layoutStyles,
        class: cssClasses,
      }),
    );
  }
  return h("div", {}, "Loading...");
};
</script>

<template>
  <DynamicRender />
</template>
